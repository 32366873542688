import * as t from "io-ts";
import {AssignmentCoordinationTemplate} from "../entities/assignment-coordination-template";
import {AssignmentCoordinationAnswers} from "../entities/assignment-coordination-answers";
import {omit, validateApi} from "api-common";
import {Roles} from "../entities/user";


export const assignmentCoordination = validateApi({
    getAll: {
        path: "/assignment-coordination/getAll",
        params: t.undefined,
        queryParams: t.undefined,
        returns: t.array(t.type({
            template: AssignmentCoordinationTemplate,
            answers: t.union([t.undefined, AssignmentCoordinationAnswers.props.selections])
        })),
        roles: [Roles.ACTIVE]
    },
    getById: {
        path: "/assignment-coordination/getById",
        params: t.type({
            id: t.number,
        }),
        queryParams: t.undefined,
        returns: t.type({
            template: AssignmentCoordinationTemplate,
            answers: AssignmentCoordinationAnswers.props.selections
        }),
        roles: [Roles.ACTIVE]
    },
    getForUserById: {
        path: "/assignment-coordination/getForUserById",
        params: t.type({
            templateId: t.number,
            userId: t.number
        }),
        queryParams: t.undefined,
        returns: t.type({
            template: AssignmentCoordinationTemplate,
            answers: AssignmentCoordinationAnswers.props.selections
        }),
        roles: [Roles.ACTIVE, Roles.ASSIGNMENT_COORDINATOR]
    },
    updateAnswersById: {
        path: "/assignment-coordination/updateAnswersById",
        params: t.type({
            id: t.number,
            answers: AssignmentCoordinationAnswers.props.selections
        }),
        queryParams: t.undefined,
        returns: t.undefined,
        roles: [Roles.ACTIVE]
    },
    updateAnswersForUser: {
        path: "/assignment-coordination/updateAnswersForUser",
        params: t.type({
            templateId: t.number,
            userId: t.number,
            answers: AssignmentCoordinationAnswers.props.selections
        }),
        queryParams: t.undefined,
        returns: t.undefined,
        roles: [Roles.ACTIVE, Roles.ASSIGNMENT_COORDINATOR]
    },
    addTemplate: {
        path: "/assignment-coordination/addTemplate",
        params: t.type(omit(AssignmentCoordinationTemplate.props, "id")),
        queryParams: t.undefined,
        returns: t.type({
            success: t.boolean
        }),
        roles: [Roles.ACTIVE, Roles.ASSIGNMENT_COORDINATOR]
    },
    alterTemplate: {
        path: "/assignment-coordination/alterTemplate",
        params: t.type({
            id: t.number,
            replacement: t.partial(omit(AssignmentCoordinationTemplate.props, "id"))
        }),
        queryParams: t.undefined,
        returns: t.type({
            success: t.boolean
        }),
        roles: [Roles.ACTIVE, Roles.ASSIGNMENT_COORDINATOR]
    },
    deleteTemplate: {
        path: "/assignment-coordination/deleteTemplate",
        params: t.type({
            id: t.number,
        }),
        queryParams: t.undefined,
        returns: t.type({
            success: t.boolean
        }),
        roles: [Roles.ACTIVE, Roles.ASSIGNMENT_COORDINATOR]
    },
    getAnswers: {
        path: "/assignment-coordination/getAnswers",
        params: t.type({
            templateId: t.number,
        }),
        queryParams: t.undefined,
        returns: t.type({
            template: AssignmentCoordinationTemplate,
            answers: t.array(t.type({
                firstName: t.string,
                lastName: t.string,
                answers: AssignmentCoordinationAnswers.props.selections
            }))
        }),
        roles: [Roles.ACTIVE, Roles.ASSIGNMENT_COORDINATOR]
    }
})