












































import Vue from 'vue';
import {Backend} from "api-frontend";
import {User} from 'mp-common/src/types/entities/user';
import {assignmentCoordination} from "mp-common/src/types/api/assignmentCoordinationApi";
import {
  AssignmentCoordinationDate, AssignmentCoordinationTemplate,
} from "mp-common/src/types/entities/assignment-coordination-template";
import {formatDate, formatTime} from "@/helpers/format-date-time";
import {LocalDate, LocalTime} from "js-joda";
import {TypeOf} from "io-ts";

export default Vue.extend({
  name: 'AssignmentCoordinationResult',
  props: {
    user: Object as () => User,
    backend: Backend
  },
  data() {
    return {
      template: undefined as AssignmentCoordinationTemplate | undefined,
      answers: undefined as TypeOf<typeof assignmentCoordination.getAnswers.returns.props.answers> | undefined,
      sortDates: {
        sortBy: 'date',
        mode: 'asc'
      },
      sortTimes: {
        sortBy: 'time',
        mode: 'asc'
      },
      showMesses: false,
      currentDate: {} as AssignmentCoordinationDate,
      currentDateIndex: 0
    }
  },
  created: async function () {
    await this.fetchCoordinationAnswers()
  },
  methods: {
    fetchCoordinationAnswers: async function () {
      const id = this.$route.query.id;
      const result = await this.backend.query(assignmentCoordination.getAnswers, {templateId: Number(id)});
      this.template = result?.template;
      this.answers = result?.answers;
    },
    customSortDates(value: any[]) {
      return value.sort((a, b) => {
        const sortBy = this.sortDates.sortBy;

        if (this.sortDates.mode === 'desc') {
          if (a[sortBy] instanceof LocalDate) {
            return b[sortBy].compareTo(a[sortBy]);
          }
          return b[sortBy].localeCompare(a[sortBy])
        }
        if (a[sortBy] instanceof LocalDate) {
          return a[sortBy].compareTo(b[sortBy]);
        }
        return a[sortBy].localeCompare(b[sortBy])
      })
    },
    customSortTimes(value: any[]) {
      return value.sort((a, b) => {
        const sortBy = this.sortTimes.sortBy;

        if (this.sortTimes.mode === 'desc') {
          if (a[sortBy] instanceof LocalTime) {
            return b[sortBy].compareTo(a[sortBy]);
          }
          return b[sortBy].localeCompare(a[sortBy])
        }
        if (a[sortBy] instanceof LocalTime) {
          return a[sortBy].compareTo(b[sortBy]);
        }
        return a[sortBy].localeCompare(b[sortBy])
      })
    },
    formatDate: (date: LocalDate) => formatDate(date),
    formatTime: (time: LocalTime) => formatTime(time),
    openMessesDialog: function (date: AssignmentCoordinationDate) {
      if (this.template) {
        this.currentDate = date;
        this.currentDateIndex = this.template.assignmentDates.indexOf(date);
        this.showMesses = true;
      }
    },
    getSortedMinis(dateIndex: number, timeIndex: number, optionIndex: number) {
      const availableMinis = this.answers?.filter((answer) => answer.answers[dateIndex][timeIndex][optionIndex] === true).sort((item, next) => item.lastName.localeCompare(next.lastName)) ?? [];
      return availableMinis.map((mini) => `${mini.firstName} ${mini.lastName}`).join(", ");
    }
  }
})
