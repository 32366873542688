import * as t from "io-ts";
import {TypeOf} from "io-ts";

export const AssignmentCoordinationAnswers = t.type({
    id: t.number,
    userId: t.number,
    templateId: t.number,
    selections: t.array(t.array(t.array(t.boolean)))
})

export type AssignmentCoordinationAnswers = TypeOf<typeof AssignmentCoordinationAnswers>