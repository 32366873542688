import * as t from "io-ts";
import {TypeOf} from "io-ts";
import {tsLocalDate, tsLocalTime, tsStringEnum} from "api-common";


export const AssignmentCoordinationOptions = ["Ich habe Zeit", "Ich möchte ministrieren"];
const AssignmentCoordinationOptionsEnum = tsStringEnum(...AssignmentCoordinationOptions);

export const AssignmentCoordinationDate = t.intersection([
    t.type({
        date: tsLocalDate,
        times: t.array(t.intersection([
            t.type({
                time: tsLocalTime,
            }),
            t.partial({
                description: t.string,
                holiday: t.boolean
            })
        ]))})
    ,
    t.partial({
        description: t.string
    })
])
export type AssignmentCoordinationDate = TypeOf<typeof AssignmentCoordinationDate>;

export const AssignmentCoordinationTemplate = t.type({
    id: t.number,
    name: t.string,
    description: t.string,
    assignmentDates: t.array(AssignmentCoordinationDate),
    preSelects: t.array(t.array(t.array(t.boolean))),
    options: t.array(AssignmentCoordinationOptionsEnum),
    isActive: t.boolean
})

export type AssignmentCoordinationTemplate = TypeOf<typeof AssignmentCoordinationTemplate>