import {DateTimeFormatter, DayOfWeek, LocalDate, LocalTime} from "js-joda";

const days = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];

export function formatDate(date: LocalDate) {
    return formatDayOfWeek(date.dayOfWeek()) + ", " + date.format(DateTimeFormatter.ofPattern("dd.MM.yyyy"));
}

export function formatTime(time: LocalTime) {
    return time.format(DateTimeFormatter.ofPattern("HH:mm"));
}

export function formatDayOfWeek(day: DayOfWeek) {
    return days[day.value() - 1];
}